<template>
  <div class="mainPage">
      <p class="pageTitle">CPSC Export</p>
      <div class="pageWrap">
        <a-button class="item" @click="handleExport">CPSC Export</a-button>
    </div>
  </div>
</template>

<script>
import btnIsPermissions from '../../utils/btnPermissions'
export default {
  methods: {
    async handleExport () {
      const isPermision = await btnIsPermissions('exportCPSC')
      if (!isPermision) return

      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/exportCpscReport')
      location.href = res.data
    }
  }
}
</script>
