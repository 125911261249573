import { message } from 'ant-design-vue'

// 0第三方客服、1erp用户、2管理员、3中国区客服专员、4中国区客服主管，5美国区客服专员，6美国区客服主管 7第三方主管
export const btnPermissionsRoles = {
  remarkRoles: [0, 2, 3, 4, 5, 6, 7], // 备注工单
  reviewRoles: [0, 2, 3, 4, 5, 6, 7], // 审核工单
  editTicketRoles: [0, 2, 3, 4, 5, 6, 7], // 编辑（地址\图片\SKU）
  cancelTicket: [2, 4], // 去掉工单
  activationTicket: [2, 4], // 激活工单
  escalateTicket: [0, 2, 3, 4, 5, 6, 7], //升级工单
  toCommonTicket: [2, 4, 6], // 降级工单
  reissueTicket: [2, 4], // 补发
  remarkStatus: [2, 3, 4, 6], // 改变按钮状态
  exportTicket: [1, 2, 4, 6], // 导出工单权限
  exportTicketDetail: [2, 4, 6], // 导出工单权限
  exportCPSC: [2, 4, 6], //CPSC导出
  userData: [0, 1, 2, 4, 5, 6, 7], // 用户查看数据权限
}

export default function btnIsPermissions(btnName) {
  if (!btnName) return
  const roleList = btnPermissionsRoles[btnName]
  const role = Number(JSON.parse(localStorage.getItem('userInfo'))?.role)
  if (roleList.includes(role)) {
    return Promise.resolve(true)
  } else {
    message.warning('You do not have this permission. Please contact the administrator.')
    return Promise.resolve(false)
  }
}